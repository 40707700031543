import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField, Typography, Button, FormLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom>
            CONFIRMATION 
            </Typography>
            <Typography variant="body1" gutterBottom>
            Please confirm that the property information is correct. If any of this information is incorrect, please contact us, and our team will make the required corrections.
            </Typography>
 
            <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <TextField 
                id="name" 
                fullWidth
                label="Property name" 
                variant="outlined" 
            />
            </Grid>
            <Grid item xs={12}>
           <TextField 
                id="neighborhood" 
                fullWidth
                label="Neighborhood" 
                variant="outlined" 
            />
             </Grid>
            <Grid item xs={12}>
                       <TextField 
                id="address" 
                fullWidth
                label="ADDRESS" 
                variant="outlined" 
            />
             </Grid>
            <Grid item xs={12}>
                       <TextField 
                id="website" 
                fullWidth
                label="WEBSITE" 
                variant="outlined" 
            />
             </Grid>
            <Grid item xs={12}>
                       <TextField 
                id="developer" 
                fullWidth
                label="Developer" 
                variant="outlined" 
            />
             </Grid>
            <Grid item xs={12}>
                       <TextField 
                id="brokerage" 
                fullWidth
                label="Brokerage" 
                variant="outlined" 
            />
             </Grid> 
             </Grid>
             <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item xs={3}>
             <Button 
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit} 
          >
            Save
          </Button>
          </Grid><Grid item xs={3}>
          <Button 
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit} 
          >
            Cancel
          </Button>
          </Grid>
          </Grid>
 
            </Paper>
            
        </Grid> 
      </Grid>
    </div>
  );
}


 





