import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';


import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CropOutlinedIcon from '@material-ui/icons/CropOutlined';

export default function AddProperty() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {

  }

  return (
    <div>
       <IconButton onClick={handleClickOpen}  >
     <AddIcon /> 
           </IconButton><span>Add a property</span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add a property</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please fill out the form below.
          </DialogContentText>
          <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item xs={12}>
           <TextField 
                id="unit" 
                fullWidth
                label="Unit" 
                variant="outlined" 
            />
             </Grid>
          <Grid item xs={6}>
          <FormControl variant="filled" fullWidth >
            <InputLabel id="status-label">Status</InputLabel>
            <Select
            labelId="status"
            id="status" 
            fullWidth
            onChange={handleChange}
            value={0}
            > 
            <MenuItem value={0}>Available</MenuItem>
            <MenuItem value={1}>Off Market</MenuItem>
            <MenuItem value={2}>In Contract</MenuItem>
            <MenuItem value={3}>Sold</MenuItem>
            <MenuItem value={4}>Closed</MenuItem>
            </Select>
        </FormControl>
            </Grid>
            <Grid item xs={6}>
                       <TextField 
                id="price" 
                fullWidth
                label="Price" 
                variant="outlined" 
            />
             </Grid>
          
            <Grid item xs={6}>
                       <TextField 
                id="beds" 
                fullWidth
                label="Beds" 
                variant="outlined" 
            />
             </Grid>
             <Grid item xs={6}>
                       <TextField 
                id="baths" 
                fullWidth
                label="Baths" 
                variant="outlined" 
            />
             </Grid>

             <Grid item xs={6}>
                       <TextField 
                id="intSqFeet" 
                fullWidth
                label="Interior Sq Ft" 
                variant="outlined" 
            />
             </Grid>
             <Grid item xs={6}>
                       <TextField 
                id="extSqFeet" 
                fullWidth
                label="Exterior Sq Ft" 
                variant="outlined" 
            />
             </Grid>


             <Grid item xs={6}>
                       <TextField 
                id="commonCharges" 
                fullWidth
                label="Common Charges" 
                variant="outlined" 
            />
             </Grid>
             <Grid item xs={6}>
                       <TextField 
                id="taxes" 
                fullWidth
                label="Taxes" 
                variant="outlined" 
            />
             </Grid>

             <Grid item xs={12}>
                    Floor plan:    <input type="file" 
                id="documents" 
                fullWidth
                label="Common Charges" 
                variant="outlined" 
            />
             </Grid>

            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
