import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

 

export default function Properties({ history }) {
  const classes = useStyles();

  const [rows, setRows ] = useState([{ name: 'Amankila Bali- Test', created: 1614556484000, updated: 1614556484000, user: 'afeldman', id: 'testing-property'}])

    const click = () => {
        history.push(`/h30d4a7f-024d-41b4-9728-591203de7bdd/dev-portal?view=confirmation`); 
    }

  return (
      <div>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell align="right">Date added</TableCell> 
            <TableCell align="right">Last updated</TableCell> 
            <TableCell align="right">Updated by</TableCell> 
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                 { row.name}
              </TableCell>
              <TableCell align="right">{ moment(row.created).format('MM-DD-YYYY HH:mm') }</TableCell> 
              <TableCell align="right">{ moment(row.updated).format('MM-DD-YYYY HH:mm') }</TableCell> 
              <TableCell align="right">{ row.user }</TableCell> 
              <TableCell align="right"><a style={{ cursor: 'pointer'}} onClick={click}>select</a> | delete</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
