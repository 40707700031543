import axios from 'axios';
import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, PHASE } from './constants'; 

const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error && error.response && error.response.status === 401) {
        //window.location = "/login";
    }
    return error;
});


const apiManager = {
    baseUrl: API,
    getHeaders: function () {
        const token = sessionStorage.getItem('token')
        return {
            token
        };
    },
     
    getShortLink: async function(data){
        try {
            let headers = this.getHeaders();
            headers.token = 'ea6b194b-46c2-414a-beb8-e38ace97ba10';

            const response = await axios.post(this.baseUrl + `/url/urlshortner/shrink/token`, data, { headers });            
            return response.data;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    },
    init: async function (payload, brandAuthorName, iv) {
        try {
            const headers = this.getHeaders();
            const response = await axios.post(this.baseUrl + `/info/init`, { payload, brandAuthorName, iv }, { headers });

            if (response && response.data) {
                sessionStorage.setItem("token", response.data.jwt);
                if (response.data.sso && response.data.sso.network) {
                    sessionStorage.setItem("network", response.data.sso.network);
                }
            }
            return response.data;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    },
     
    getPayload: async function (payload, brandAuthorName) {
        try {
            const headers = this.getHeaders();
            const response = await axios.post(this.baseUrl + `/care/decrypt/payload`, { payload, brandAuthorName }, { headers });

            return response.data;
        }
        catch (ex) {
            console.log(ex);
        }
        return {};
    }, 
    update: async function (payload, jwt, queryToken, clientPath) {
        try {
            const { first, last, mobile, pin, facebook } = payload;
            let headers = this.getHeaders();
            headers.authorization = "Bearer " + sessionStorage.getItem("token");

            const response = await axios.post(this.baseUrl + `/${clientPath}/update`, { ...payload, jwt, queryToken }, { headers });

            return response.data;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    },
    validate: async function (queryToken, clientPath) {
        try {
            let headers = this.getHeaders();
            headers.authorization = "Bearer " + sessionStorage.getItem("token");

            const response = await axios.post(this.baseUrl + `/${clientPath}/validate`, { queryToken }, { headers });

            return response.data;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    },
    success: async function (jwt) {
        try {
            let headers = this.getHeaders();
            headers.authorization = "Bearer " + sessionStorage.getItem("token");

            const response = await axios.post(this.baseUrl + `/info/success`, { jwt }, { headers });

            return response.data;
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    },
    purge: async function (payload, brandAuthorName, iv) {
        try {
            const headers = this.getHeaders();
            headers.token = "ea6b194b-46c2-414a-beb8-e38ace97ba10";
            const response = await axios.post(this.baseUrl + `/info/purge`, { payload, brandAuthorName, iv }, { headers });
            if (response && response.data) {
                return response.data;
            }
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    }, 
    auth: async function (data) {
        try {
            const { username, password } = data;
            const headers = this.getHeaders();
            headers.token = "ea6b194b-46c2-414a-beb8-e38ace97ba10";
            const response = await axios.post(this.baseUrl + `/admin/users/auth`, data, { headers });
            if (response && response.data) {
                return response.data;
            }
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    }, 
    get: async function (data) {
        try { 
            const { endpoint } = data;
            const headers = this.getHeaders(); 
            const response = await axios.get(this.baseUrl + endpoint, { headers });
            if (response && response.data) {
                return response.data;
            }
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    }, 
    post: async function (data) {
        try { 
            const { endpoint, payload } = data;
            const headers = this.getHeaders(); 
            const response = await axios.get(this.baseUrl + endpoint, payload, { headers });
            if (response && response.data) {
                return response.data;
            }
        }
        catch (ex) {
            console.log(ex);
            return false;
        }
    }, 
}


export default apiManager;