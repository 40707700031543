import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, CURV10_GUID, PHASE } from '../../constants';
 

const BASE_URL = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;

const SuccessPath = `/${CURV10_GUID}/success`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    borderRadius: '10em',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '280px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  }
}));

 
 
const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;

  const [init, setInit] = useState(false);
  const [user, setUser] = useState(false);

  const [loading, setLoading] = useState(true);

   
  const getJwt = async (payload) => {

    const token = await apiManager.init(payload, `prototype-curve10-${PHASE}`);
    
    if (!token) { 
      //history.push(ErrorPath)
    }
    else { 
      sessionStorage.setItem('network', token.sso.network);
      sessionStorage.setItem('client', token.decryptedPayload.brandAuthorName);
      sessionStorage.setItem('jwt', token.jwt);
      sessionStorage.setItem('user', JSON.stringify(token.decryptedPayload));
      setUser(token.decryptedPayload)
    }
    setLoading(false);
  };

  let payload = 'O_tHadVZTmkmc6fjZ_xA0CArnnYYnk9ZJciHULk4bexRtAn3rvM25I3-VF6KK_-Rk3uQLXasTtde7jRTiFpSHqbHd5zWKbKMcEF_9lWiHmvTgWLIBiH7tmbIXfcm4uiwwFA_wqtdJ-HPNvDPVnNi5Ut3iYm6T3eqNWpcICOzwvHRBrsqZLta5q7WOdsAyQzNxOvVfr8TIZhX_CuRUFvHPX5GElQN0Fhqe9fAui6zCoZwiaXGQp4J-QA-oWEIEl2F15hWpzur8EcTaboiMqRAVnNEdFTay-gO1-IidWWzky-jdEJB5Kd4OYtkCwA9VhwnU9Q6KL7vxym07QISYtGc0xtCPagY01wX8mt17CA9mZ6_wpmwPA9am3c30CZ9tvLSRwZ1HIryRrrRb6UqF6Kt7QK30GQhL94-6sF8MLFTo99BbYQ2GTrn4P2C9qY45Y_9mo1OL82kJsYkqLLIVLt2VVcjNGp56ZKbTKGQypV78mwCGlgE6f6v1fOzJw4277x4KyOKNKmLAtPpCNq3kdkkGDHX0_z1BVM8lrdcsA%3D%3D';
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }


  if (!init) {
    getJwt(payload);
    setInit(true);
  }

  return (
    <div className={classes.root}>
      {loading && (
        <Grid
          className={classes.progressWrapper}
          container
          item
          lg={12}
          xs={12}
        >
          <CircularProgress className={classes.progress} />
        </Grid>
      )}
      { user && 
        <h3>Welcome { user.authorName }</h3>
      }
     
    </div >
  );
};

export default Landing;
