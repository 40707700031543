import React from 'react'; 
import { Curve10Logo } from './components/logos/index';
import { Curve10Favicon } from './images/favicon.ico';

const curve10Header = {
    headerLogo: Curve10Logo,
    favicon: Curve10Favicon,
    headerLogoUrl: 'https://www.curve10.com/',
    headerColor: '#f8f8f8',
    preHeaderColor: '#f8f8f8'
};

const curve10Background = {
    position: '0 100%',
    size: 'cover',
    repeat: 'no-repeat'
}

const curve10Landing = {
    
    pageTitle: 'Account Verification',
    contentTitle: 'Account Verification',
    contentDescription: 'In order to help us better serve your needs, we will need you to login',
    email:'Email',
    password:'Password', 
}; 

const curve10ScreenConfigs = {
    landing: curve10Landing,
    header: curve10Header,
    background: curve10Background, 
}

const lxScreenConfigs = {
    landing: curve10Landing,
    header: curve10Header,
    background: curve10Background, 
}

export {
    curve10ScreenConfigs,
    lxScreenConfigs, 
};