import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, Empty as EmptyLayout, LXPortalLayout } from './layouts'; 

import apiManager from './ApiManager';
import { 
  curve10ScreenConfigs, 
  lxScreenConfigs  } from './configs';

import {
  CURV10_GUID,
  LX_GUID, 
} from './constants';

import { 
  Curve10Landing,
  Curve10Success,
  Curve10Default,
  Curve10Dashboard,
  Curve10Clients, Curve10Logs, Curve10Integrations,
  LXDevPortal

} from './views';



class Routes extends React.PureComponent {
  constructor(props) {
    super(props);
     
    const { user, updateTheme, history } = this.props;
    this.state = {
      user: {},
      apiManager,
      updateTheme,
      history
    };
    //this.updateUser = this.updateUser.bind(this);
  }

  componentDidUpdate() {
    const { user } = this.state;
    const { history } = this.props; 
  }

  updateUser(user) {
    this.setState({ user })
    //localStorage.setItem('user', JSON.stringify(user));
  }

  render() {
    const { user, apiManager, updateTheme, history } = this.state;

    return (
      <Switch>

        { /*ROOT screen*/}
        <RouteWithLayout component={Curve10Default} exact layout={EmptyLayout} path={`/`} apiManager={apiManager} screenConfig={curve10ScreenConfigs} />

        { /*Curve10 screens*/} 
        <RouteWithLayout component={Curve10Success} exact layout={MinimalLayout} path={`/${CURV10_GUID}/landing`} apiManager={apiManager} screenConfig={curve10ScreenConfigs.header} />, 
        <RouteWithLayout component={Curve10Dashboard} exact layout={MainLayout} path={`/${CURV10_GUID}/dashboard`} apiManager={apiManager} screenConfig={curve10ScreenConfigs.header} />, 
        <RouteWithLayout component={Curve10Clients} exact layout={MainLayout} path={`/${CURV10_GUID}/clients`} apiManager={apiManager} screenConfig={curve10ScreenConfigs.header} />, 
        <RouteWithLayout component={Curve10Logs} exact layout={MainLayout} path={`/${CURV10_GUID}/logs`} apiManager={apiManager} screenConfig={curve10ScreenConfigs.header} />, 
        <RouteWithLayout component={Curve10Integrations} exact layout={MainLayout} path={`/${CURV10_GUID}/integrations`} apiManager={apiManager} screenConfig={curve10ScreenConfigs.header} />, 

        <RouteWithLayout component={LXDevPortal} exact layout={LXPortalLayout} path={`/${LX_GUID}/dev-portal`} apiManager={apiManager} screenConfig={curve10ScreenConfigs.header} updateTheme={updateTheme} history={history} />, 

      </Switch>
    )
  }
};



Routes.propTypes = {
  user: PropTypes.object,
  updateTheme: PropTypes.func,
};

export default withRouter(Routes);
