import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography'; 

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CropOutlinedIcon from '@material-ui/icons/CropOutlined';

import CropModal from './CropModal'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));



const bottom = [
     
    {
      src: 'https://www.lxcollection.com/wp-content/uploads/2020/11/view-from-amankila-1200x800-1-scaled.jpg',
      height: 209, width: 207 
      
    },
    {
      src: 'https://www.lxcollection.com/wp-content/uploads/2020/11/amankila_pool2_office_15984-scaled.jpg',
      height: 209, width: 233
      
    },
    {
      src: 'https://www.lxcollection.com/wp-content/uploads/2020/11/ricefields_mount_agung_office_11476amankila-scaled.jpg',
      height: 156, width: 180,  
      
    },
     
  ];



  const top = [ 
    {
        src: 'https://www.lxcollection.com/wp-content/uploads/2020/11/amankila_sunrise_cruise-0347_2-1.jpg',
        height: 207, width: 249
      },
      {
        src: 'https://www.lxcollection.com/wp-content/uploads/2020/11/private-sand-beach-amankila-scaled.jpg',
        height: 163, width: 191 
      },
      {
        src: 'https://www.lxcollection.com/wp-content/uploads/2020/11/amankila-romance-525x525_0.jpg',
        height: 260, width: 180
      }, 
     
  ];

export default function PrimaryImages() {
  const [spacing, setSpacing] = React.useState(2);

  
  const [image, setImage] = React.useState('https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000');

  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [aspect, setAspect] = React.useState(4/3); 



  const classes = useStyles();
  const masonryOptions = {
    transitionDuration: 0
};

const selectFile = (e) => {
    
     e.currentTarget.children[0].children[1].click()
}

const cropFile = () => {

}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


const onChangeFile = async (event)  => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file); 

    const sample = await toBase64(file)
    setImage(sample)
}
 
const imagesLoadedOptions = { background: '.my-bg-image-el' }

const topElements = top.map((element) => {
    const { height, width, src } = element;
    return (
        <div style={{ float: 'left', padding: 10,  minHeight: 230, position: 'relative'}}>
            <IconButton onClick={selectFile} style={{ position: 'absolute', top: 15, right: 15, border: '1px solid #999'}}>
         
                <EditIcon />
                <input style={{ display: 'none'}} id="fileUpload" type="file" accept="image/*"  onChange={onChangeFile}/>
           </IconButton>
           <CropModal image={image} crop={crop} aspect={aspect} zoom={zoom} />
           
          
                <img src={src} style={{ ...element }} />
                
               
              
        </div>
     );
 });

 const bottomElements = bottom.map((element) => {
    const { height, width, src } = element;
    const margin =  height < 180 ? '' : '-7%'
    return (
        <div   style={{ float: 'left', padding: 10,  minHeight: 230, marginTop: margin, position: 'relative'  }} >
             <IconButton onClick={selectFile} style={{ position: 'absolute', top: 15, right: 15, border: '1px solid #999'}}>
         
         <EditIcon />
         <input style={{ display: 'none'}} id="fileUpload" type="file" accept="image/*"  onChange={onChangeFile}/>
    </IconButton>
             <img src={src} style={{ ...element   }} />
        </div> 
     );
 });

  return (
 
    <div>
           <Typography variant="h2" gutterBottom>
           AMENITIES IMAGE UPLOAD

            </Typography>
            <Typography variant="body1" gutterBottom>
            The following images will be used in a collection of images associated with the amenities of your property. This placement will accommodate up to six (6) images and no fewer than three (3). Please upload images no larger than 20mb, with a minimum dimension of 1000x1000 at 72 dpi. Each image will be placed in a specific order, and while they may appear smaller in this mosaic, all will enlarge upon click. Please refer to the ℹ if you would like to know the exact location of each image in the mosaic.            </Typography>

   
    <div style={{ width: 750 }}>
     
    
      <div   >
                    { topElements }
 
                    { bottomElements }
                    </div>
                </div>


 </div>
  );
}
