import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slider from '@material-ui/core/Slider';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Cropper from 'react-easy-crop'

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CropOutlinedIcon from '@material-ui/icons/CropOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CropModal(image, crop, zoom, aspect ) {
  const [open, setOpen] = React.useState(false);
 
 
  const onCropChange = (crop) => {
    //this.setState({ crop })
  }
 
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
  }
 
  const onZoomChange = (zoom) => {
    //this.setState({ zoom })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} style={{ position: 'absolute', top: 15, right: 75, border: '1px solid #999'}}>
           <CropOutlinedIcon />
           </IconButton>
      <Dialog
        open={open} 
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Crop image</DialogTitle>
        <DialogContent>
        <div style={{ width: 800, height: 600 }}>
                <Cropper
                  image={'https://www.lxcollection.com/wp-content/uploads/2020/11/private-sand-beach-amankila-scaled.jpg'}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={onCropChange}
                  onCropComplete={onCropComplete}
                  onZoomChange={onZoomChange}
                />
</div>
<div className="controls">
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={onZoomChange}
          classes={{ container: 'slider' }}
        />
      </div>
            
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
