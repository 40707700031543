import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Welcome from './components/Welcome'
import Properties from './components/Properties'
import Confirmation from './components/Confirmation'
import ContactInfo from './components/ContactInfo'
import PropertyDetails from './components/PropertyDetails'
import PropertyAmenities from './components/PropertyAmenities'
import LuxuryUnitTypes from './components/LuxuryUnitTypes'
import Availability from './components/Availability'
import TermsAndConditions from './components/TermsAndConditions'
import Faq from './components/Faq'
import PrimaryImages from './components/PrimaryImages'
import ArchitectualImages from './components/ArchitectualImages'
import InteriorDesignImages from './components/InteriorDesignImages'
import AmenitiesImages from './components/AmenitiesImages'






const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

sessionStorage.setItem('thmem', 'dark')

 

export default function DevPortal({ apiManager, updateTheme, history }) {
    const classes = useStyles();

    const [rows, setRows ] = useState([])

    updateTheme('dark')

    const renderContent = () => {
        let output = <Welcome />
        let { search } = history.location;
        search = search.replace('?view=', '');
       
        switch(search){
            case 'photos/amenities':
                output = <div><AmenitiesImages /></div>
                break;
            case 'photos/interior-design':
                output = <div><InteriorDesignImages /></div>
                break;
            case 'photos/architectural':
                output = <div><ArchitectualImages /></div>
                break;
            case 'photos/primary':
                output = <div><PrimaryImages /></div>
                break;
            case 'faq':  
                output = <div><Faq /></div>
                break;
            case 'welcome':  
                output = <div><Welcome /><Properties history={history} /></div>
                break;
            case 'confirmation':
                output = <div><Confirmation /></div>
                break;
            case 'contact-info':
                output = <div><ContactInfo /></div>
                break;
            case 'property-details':
                output = <div><PropertyDetails /></div>
                break;
            case 'luxury-unit-types':
                output = <div><LuxuryUnitTypes /></div>
                break;
            case 'amenities':
                output = <div><PropertyAmenities /></div>
                break;
            case 'availability':
                output = <div><Availability /></div>
                break;
            case 'terms-and-conditions':
                output = <div><TermsAndConditions /></div>
                break;                
            default: 
                output = <Welcome />
                
        }
        return output;
    }


  return (
     renderContent() 
  );
}
