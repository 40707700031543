import { createMuiTheme } from '@material-ui/core/styles';

const dark = createMuiTheme({
  typography: {
    fontSize: 20
  },
  palette: {
    type: 'dark',
    default: {
      main: '#000',
      light: '#fff',
      dark: '#999'
    },
    primary: {
      main: '#000'
    },
    secondary: {
      main: '#999'
    },
    appBar: {
      color: '#999'
    },
  },
  
});

export default dark;
