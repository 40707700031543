import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListSubheader, ListItemText } from '@material-ui/core'; 

import CheckCircleIcon from '@material-ui/icons/CheckCircle'; 
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export default function PrimaryMenu({ history }) {

    const click = (route) => { 
        history.push(`/h30d4a7f-024d-41b4-9728-591203de7bdd/dev-portal?view=${route}`);
    }

    const mainListItems = (
        <div> 
    <ListSubheader>Upload Images</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <ErrorOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="PRIMARY/SECONDARY" onClick={e => click('photos/primary')} />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ErrorOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="ARCHITECTURAL" onClick={e => click('photos/architectural')} />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ErrorOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="INTERIOR DESIGN" onClick={e => click('photos/interior-design')} />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ErrorOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="AMENITIES" onClick={e => click('photos/amenities')} />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <HelpOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="FAQ" onClick={e => click('faq')} />
    </ListItem>
  </div>
    );

  return (mainListItems);

}