import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'; 

import CheckCircleIcon from '@material-ui/icons/CheckCircle'; 
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function PrimaryMenu({ history }) {

    const click = (route) => { 
        history.push(`/h30d4a7f-024d-41b4-9728-591203de7bdd/dev-portal?view=${route}`);
    }

    const mainListItems = (
        <div>
        <ListItem button>
            <ListItemIcon>
            <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Welcome" onClick={e => click('welcome')} />
        </ListItem> 
        <ListItem button>
            <ListItemIcon>
            <ErrorOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Confirmation" onClick={e => click('confirmation')} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
            <ErrorOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Contact info" onClick={e => click('contact-info')} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
            <ErrorOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Property details" onClick={e => click('property-details')} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
            <ErrorOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Luxury unit types" onClick={e => click('luxury-unit-types')} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
            <ErrorOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Amenities" onClick={e => click('amenities')} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
            <ErrorOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="AVAILABILITY" onClick={e => click('availability')} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
            <ErrorOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="TERMS AND CONDITIONS" onClick={e => click('terms-and-conditions')} />
        </ListItem>
        
        </div>
    );

  return (mainListItems);

}