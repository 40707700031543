import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

//import Curve10Logo from '../../components/logos'
import Curve10Logo from '../../images/favicon-144px-V2.png'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.curve10.com/">
        Curve10
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn({ apiManager }) {
  const classes = useStyles();

  const [username, setUsername ] = useState('')
  const [password, setPassword ] = useState('')

  const auth = async() => {
    const authResult = await apiManager.auth({ username, password })
    debugger;
    if(authResult){
      const { token, guid } = authResult;
      sessionStorage.setItem('token', token)
        delete authResult.token
        sessionStorage.setItem('user', JSON.stringify(authResult))
        window.location = `/${guid}/dashboard`
    }
    else {
      alert('bad login')
    }
  }

  const handleChange = (event) => {
    const { id, value } = event.target
    switch(id){
      case 'email':
        setUsername(value)
        break;
      case 'password': 
        setPassword(value);
        break;
    }
  }

  return (
    <Container component="main" maxWidth="xs">

      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
        <img  src={Curve10Logo} style={{height: 30}}  alt="curve10"/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={username}
            id="email"
            onChange={handleChange}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={handleChange}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button 
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={auth}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> 
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <style>
            { `body {  
                background-image: linear-gradient(90deg, #d61229 4%,#362333 100%);
                background-position: center top;
                background-repeat: no-repeat; 
            }`}
        </style>
    </Container>
  );
} 