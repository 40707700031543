import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Checkbox, Typography, Button, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

const data = [
    { id: 0, name: '24hr Doorman'}, 
    { id: 1, name: 'Ballroom'},
    { id: 0, name: 'Basketball court'}, 
    { id: 1, name: 'Beach access'},
    { id: 0, name: 'Bike storage'}, 
    { id: 1, name: 'Bowling alley'},
    { id: 1, name: 'Boxing room'},
    { id: 1, name: `Children's playroom`},
    { id: 1, name: 'Cigar room'},
    { id: 1, name: 'Cold storage'},
    { id: 1, name: 'Concierge'},
    { id: 1, name: 'Conference room'},
    { id: 1, name: 'Coworking space'},
    { id: 1, name: 'Demonstration kitchen'},
    { id: 1, name: 'Hotel services'},
    { id: 1, name: 'Squash court'},
    { id: 1, name: 'Swimming pool'},
    { id: 1, name: 'Shuttle service'},
    { id: 1, name: 'Terrace'},
    { id: 1, name: 'Valet parking'},
    { id: 1, name: 'Wine storage'},
    { id: 1, name: 'Wine tasing room'},
    { id: 1, name: 'Yoga studio'},
]

export default function PropertyAmenities() {
  const classes = useStyles();

  const [amenities, setAmenities] = useState(data)
  const [addAmenity, setAddAmenity] = useState('')


  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      
      amenities.push({ id: 1, name: addAmenity})
      setAmenities(amenities)
      setAddAmenity('')
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom>
            PROPERTY AMENITIES
 
            </Typography>
            <Typography variant="body1" gutterBottom>
            Please select all relevant amenities. If you do not see a specific amenity, you may suggest up to five additional custom amenities for our editorial team to review. (Check all that apply.)                                                </Typography>
 
            <Grid component="label" container alignItems="center" spacing={1}>
            { 
                amenities.map(value => {
                    return (<Grid item xs={3}> 
                        <Checkbox 
                            id={value.id}
                            fullWidth
                            label={value.name}
                            variant="outlined" 
                        /> {value.name}
                    </Grid>)
                })
            }
          
            
             </Grid>
<br />
             <Grid item xs={12}>
                       <TextField 
                id="new" 
                fullWidth
                label="Add a new amenity, click enter to append to the list above" 
                variant="outlined" 
                onKeyDown={onKeyDown}
                value={addAmenity}
                onChange={e => setAddAmenity(e.target.value)}
            />
             </Grid> 
             <br />

             <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item xs={3}>
             <Button 
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit} 
          >
            Save
          </Button>
          </Grid><Grid item xs={3}>
          <Button 
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit} 
          >
            Cancel
          </Button>
          </Grid>
          </Grid>
 
            </Paper>
            
        </Grid> 
      </Grid>
    </div>
  );
}


 





