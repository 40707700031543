import { flatten } from "flat";

let english = {
  provide: {
    heading: "Please provide your personal information.",
    headingTwitter: "{name}, Please provide your personal information.",
    aditionalInfo: "Additional info. Please fill at least 2 of these fields.",
  },
  defaultPage: {
    pageTitle: "Prototype",
    title: "Prototype",
    email: "Email address",
    description: "Please provide your personal information.",
    loading: "Validating social identity, please wait...",
    firstName: "First Name",
    lastName: "Last Name",
    required: "Required fields",
    submit: "Submit",
    alertError: "Something went wrong. Please contact your agent.",
  }, 
  validations: {
    required: "This field is required.",
    email: "It is not a valid email.",
    phone: "Please include a valid phone number.",
    aditionalInfo: "Please fill at least 2 fields.",
    atLeastOneFormOfContact:
      "Please provide at least one additional form of information.",
  },   
};

export default flatten(english);
