import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Checkbox, Typography, Button, FormLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

const data = [
    { id: 1, name: 'Cabana'},
    { id: 1, name: 'Duplex residences'},
    { id: 1, name: 'Full-floor residences'},
    { id: 1, name: 'Lofts'},
    { id: 1, name: 'Loggias'},
    { id: 1, name: 'Penthouse'},
    { id: 1, name: 'Townhouse'},
    { id: 1, name: 'Triplex penthouse'},
    { id: 1, name: 'Villa'},
]

export default function LuxuryUnitTypes() {
  const classes = useStyles();
  const [unitTypes, setUnitTypes] = useState(data)
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom>
            LUXURY UNIT TYPES
 
            </Typography>
            <Typography variant="body1" gutterBottom>
            Please indicate if your property features any of these unique luxury unit types. (Check all that apply.)
                                    </Typography>
 
            <Grid component="label" container alignItems="center" spacing={1}>
            <Grid component="label" container alignItems="center" spacing={1}>
            { 
                unitTypes.map(value => {
                    return (<Grid item xs={3}> 
                        <Checkbox 
                            id={value.id}
                            fullWidth
                            label={value.name}
                            variant="outlined" 
                        /> {value.name}
                    </Grid>)
                })
            }
          
            
             </Grid>
             </Grid>

             <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item xs={3}>
             <Button 
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit} 
          >
            Save
          </Button>
          </Grid><Grid item xs={3}>
          <Button 
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit} 
          >
            Cancel
          </Button>
          </Grid>
          </Grid>
 
            </Paper>
            
        </Grid> 
      </Grid>
    </div>
  );
}


 





