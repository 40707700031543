import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddProperty from './AddProperty'
import EditProperty from './EditProperty'
import DeleteProperty from './DeleteProperty'
import NumberFormat from 'react-number-format';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

const data = [
    { id: 0, status: 0, unit: 'Amankila Suite', beds: 1, bath: 2, intSqFeet: 500, extSqFeet: 600, price: 1000000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
    { id: 0, status: 1, unit: 'Rogers Suite', beds: 2, bath: 3, intSqFeet: 1500, extSqFeet: 600, price: 1200000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
    { id: 0, status: 2, unit: 'Stepford Suite', beds: 4, bath: 3, intSqFeet: 2500, extSqFeet: 600, price: 15000000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
    { id: 0, status: 3, unit: 'Amankila Double Suite', beds: 2, bath: 3, intSqFeet: 1500, extSqFeet: 600, price: 1000000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
    { id: 0, status: 4, unit: 'Rogers Floor', beds: 8, bath: 10, intSqFeet: 5000, extSqFeet: 600, price: 300000000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
    { id: 0, status: 0, unit: 'Amankila Floor', beds: 10, bath: 12, intSqFeet: 3000, extSqFeet: 600, price: 20000000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
    { id: 0, status: 0, unit: 'Amankila Double Suite', beds: 2, bath: 3, intSqFeet: 1500, extSqFeet: 600, price: 7500000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
    { id: 0, status: 0, unit: 'Amankila Triple Suite', beds: 3, bath: 3, intSqFeet: 2500, extSqFeet: 600, price: 12500000, commonCharges: 50000, taxes: 30000, documents: ['floor-plan.pdf', 'hoa-fees.pdf'] },
]

export default function Availability() {
  const classes = useStyles();

  const [properties, setProperties] = useState(data)

  const renderStatus = (status) => {
    let output = 'Available'    
    switch(status){
            case 1:
                output ='Off Market'
                break;
            case 2:
                output ='In Contract'
                break;
            case 3:
                output ='Sold'
                break;
            case 4:
                output ='Closed'
                break;
    }

       
      return output
  }

  return (
    <div className={classes.root}>
      <Grid spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom>
            PROPERTY AVAILABILITY
            </Typography>

            <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" style={{overflow: 'hidden'}}>
        <TableHead>
          <TableRow> 
            <TableCell align="left">Status</TableCell>
            <TableCell align="right">Unit</TableCell>
            <TableCell align="right">Beds</TableCell>
            <TableCell align="right">Baths</TableCell>
            <TableCell align="right">Int. (Sq ft)</TableCell>
            <TableCell align="right">Ext. (Sq ft)</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Common Charges</TableCell>
            <TableCell align="right">Taxes</TableCell> 
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">{renderStatus(row.status)}</TableCell>
              <TableCell align="right">{ row.unit }</TableCell>
              <TableCell align="right">{ row.beds }</TableCell>
              <TableCell align="right">{ row.bath }</TableCell>
              <TableCell align="right"><NumberFormat value={row.intSqFeet} displayType={'text'} thousandSeparator={true} /></TableCell>
              <TableCell align="right"><NumberFormat value={row.extSqFeet} displayType={'text'} thousandSeparator={true}   /></TableCell>
              <TableCell align="right"><NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
              <TableCell align="right"><NumberFormat value={row.commonCharges} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
              <TableCell align="right"><NumberFormat value={row.taxes} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell> 
              <TableCell align="right"><EditProperty /> <DeleteProperty /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    

<AddProperty /> 

            </Paper>


            
        </Grid> 
      </Grid>
    </div>
  );
}


 