import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, PHASE } from './phaseConstants';

export { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, PHASE };

// Timeouts
export const INITIAL_WARNING_DURATION = 1000 * 60 * 60; // 60 minutes
export const EXTRA_LOGOUT_DURATION = 1000 * 60 * 70; // on 70 minutes, force logout

//GUIDs
export const CURV10_GUID = 'h30d4a7f-024d-41b4-9728-591203de7bdd'; 
export const LX_GUID = 'h30d4a7f-024d-41b4-9728-591203de7bdd'; 
