import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Checkbox, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function Availability() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom>
            TERMS AND CONDITIONS
            </Typography>
             
            <div style={{ height: 300, width: '100%', maxHeight: 300, overflow: 'scroll', overflowX: 'hidden' }}>
            <Typography variant="body1" gutterBottom>
            Version Date: June 1, 2020
            </Typography>  <Typography variant="body1" gutterBottom>
1. Acceptance of the Terms of Use
The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, "Terms of Use"), govern access to and use of the property portal (the “Property Portal”) of the LX Collection.com website (the “Website”) for the purpose of submitting information concerning real property as to which You are the owner, developer or listing real estate broker (the “Developer” or “You”), and apply to any content, functionality, and services offered on or through LXCollection.com (the "Website") and the Property Portal, for the purpose of providing information concerning real properties for presentation to visitors of the Website. These Terms of Use are entered into by and between Developer and LX Collection LLC (“LX Collection, the "Company," "we," or "us").
</Typography>  <Typography variant="body1" gutterBottom>

Access to the Property Portal is only by invitation from us to Developer. In creating an account on the Property Portal, accessing such account, and submitting information for posting on the Website, the person taking such actions represents that such person is the Developer or is authorized to do so by the Developer, and has the authority to agree to these Terms of Use on the behalf of Developer. By utilizing an account on the Website (an “Account”), Developer agrees to be bound and abide by these Terms of Use. Developer also agrees to be bound by the Terms of Use for the Website, which are incorporated herein by reference, in connection with its use of the Website.
</Typography>  <Typography variant="body1" gutterBottom>
For a Developer who is an advertiser “Member” of LX Collection LLC for marketing services, the terms of the Insertion Order between Developer and us may also apply to the provision of materials for posting on the Website.
</Typography>  <Typography variant="body1" gutterBottom>
2. Changes to the Terms of Use
We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and by using the Property Portal, or by clicking to accept or agree to the Terms of Use when this option is made available to Developer, Developer accepts and agrees to be bound and abide by the Terms of Use as revised. However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Property Portal.
</Typography>  <Typography variant="body1" gutterBottom>
You are expected to check this page from time to time so You are aware of any changes, as they are binding on Developer.
</Typography>  <Typography variant="body1" gutterBottom>
3. Accessing the Property Portal and Account Security
We reserve the right to withdraw or amend the Property Portal and the Website, and any service or material we provide on the Property Portal or Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Property Portal or Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Property Portal or Website, or the entire Property Portal or Website, to users, including registered users.
</Typography>  <Typography variant="body1" gutterBottom>
You are responsible for both:
</Typography>  <Typography variant="body1" gutterBottom>
Making all arrangements necessary for You to have access to the Property Portal.
Ensuring that all persons who access the Property Portal through Your account or internet connection are authorized to do so and are aware of these Terms of Use and comply with them.
To access the Property Portal, You may be asked to provide certain registration details or other information. It is a condition of Your use of the Property Portal that all the information You provide on or through the Property Portal is correct, current, and complete. You agree that all information You provide to register with the Property Portal or otherwise, including, but not limited to, through the use of any interactive features on the Property Portal, is governed by our Privacy Policy, and You consent to all actions we take with respect to Your information consistent with our Privacy Policy.
</Typography>  <Typography variant="body1" gutterBottom>
If You choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, You must treat such information as confidential, and You must not disclose it to any other person or entity. You also acknowledge that Your account is solely for Your use and You agree not to provide any other person with access to this Website or portions of it using Your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of Your username or password or any other breach of security. You also agree to ensure that You exit from Your account at the end of each session.
</Typography>  <Typography variant="body1" gutterBottom>
You should use particular caution when accessing Your account from a public or shared computer so that others are not able to view or record Your password or other information.
</Typography>  <Typography variant="body1" gutterBottom>We have the right to disable any user name, password, or other identifier, whether chosen by You or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, You have violated any provision of these Terms of Use.

4. Intellectual Property Rights
The Property Portal and the Website and their entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
</Typography>  <Typography variant="body1" gutterBottom>
These Terms of Use permit You to use the Property Portal for Your commercial purposes only.
</Typography>  <Typography variant="body1" gutterBottom>
You must not:
</Typography>  <Typography variant="body1" gutterBottom>
Modify copies of any materials from the Property Portal or Website.
Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Property Portal or Website.
If You wish to make any use of material on the Website other than that set out in this section, please address Your request to: mailto:info@lxcollection.com.
</Typography>  <Typography variant="body1" gutterBottom>
If You print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Property Portal in breach of the Terms of Use, Your right to use the Property Portal will stop immediately and You must, at our option, return or destroy any copies of the materials You have made. No right, title, or interest in or to the Property Portal, Website or any content on the Property Portal or Website is transferred to You, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
</Typography>  <Typography variant="body1" gutterBottom>
5. Trademarks
The Company name, the term LX COLLECTION, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on the Property Portal and Website are the trademarks of their respective owners.
</Typography>  <Typography variant="body1" gutterBottom>
6. LX Collection’s Role
LX Collection does not provide real estate, financial or other advice. LX Collection is not a real estate broker or broker representative representing You or any Website user. Information about properties on this Website is provided by third parties such as You. LX Collection does not endorse any properties or information concerning properties identified on the Website, and is not responsible for the accuracy or validity of information provided by Your or any other third parties.
</Typography>  <Typography variant="body1" gutterBottom>
The Website utilizes the Google Maps web mapping service. Your incorporation of Google Maps functionality in material submitted through the Property Portal is subject to Google’s terms of use, which are available at https://www.google.com/help/terms_maps/ and Privacy Policy, available at https://policies.google.com/privacy?hl=en-US.
</Typography>  <Typography variant="body1" gutterBottom>
7. Communications From Us
By utilizing an account, You consent to our sending You information concerning the Property Portal and Website. As a matter of the proper functioning of our business relationship, You may not opt-out of receipt of such emails. As set out in our Privacy Policy, we will not sell Your information to third-parties, but we may send emails to You at the behest of third-parties.
</Typography>  <Typography variant="body1" gutterBottom>
8. Prohibited Uses
You may use the Property Portal and Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Property Portal and Website:
</Typography>  <Typography variant="body1" gutterBottom>
In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of personal information, data or software to and from the US or other countries).
To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards set out in these Terms of Use.
To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.
To make any loan-related decisions.
To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or other identifiers associated with any of the foregoing).
Modify or remove any copyright or trademark notices appearing on the Property Portal or Website.
For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Property Portal or Website, or which, as determined by us, may harm the Company or users of the Website, or expose them to liability.
Additionally, You agree not to:
</Typography>  <Typography variant="body1" gutterBottom>
Use the Property Portal or Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Property Portal or Website, including their ability to engage in real-time activities through the Property Portal or Website.
Use any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any material on the Website.
Use any manual process to monitor or copy any of the material on the Website, or for any other purpose not expressly authorized in these Terms of Use.
Use any device, software, or routine that interferes with the proper working of the Website.
Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.
Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer, or database connected to the Website.
Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
Otherwise attempt to interfere with the proper working of the Website.
</Typography>  <Typography variant="body1" gutterBottom>
9. Developer Contributions
The Property Portal allows Developers to submit content or other materials (collectively, "Developer Contributions") on or through the Property Portal.
</Typography>  <Typography variant="body1" gutterBottom>
All Developer Contributions must comply with the Content Standards set out in these Terms of Use.
</Typography>  <Typography variant="body1" gutterBottom>
Any Developer Contribution You submit to the site will be considered non-confidential and non-proprietary. By providing any Developer Contribution on the Website, You grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns a non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide license to the content, including without limitation the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose. You further understand that Website users may link pages from the Website to other web sites or social media.
</Typography>  <Typography variant="body1" gutterBottom>
You represent and warrant that:
</Typography>  <Typography variant="body1" gutterBottom>
You own or control all rights in and to the Developer Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.
All of Your User Contributions do and will comply with these Terms of Use.
Descriptions of real property, including cooperative apartment and condominium buildings and units, are accurate and consistent with the offering plan, condominium declaration or other documents filed with government agencies or recorded in land records.
You understand and acknowledge that You are responsible for any User Contributions You submit or contribute, and You, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.
We are not responsible or liable to any third party for the content or accuracy of any User Contributions submitted by You or any other user of the Website.
</Typography>  <Typography variant="body1" gutterBottom>
10. Monitoring and Enforcement; Termination
We have the right to:
</Typography>  <Typography variant="body1" gutterBottom>
Remove or refuse to use or further transmit any Developer Contributions for any or no reason in our sole discretion.
Take any action with respect to any Developer Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such Developer Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens anyone’s personal safety or could create liability for the Company.
Disclose Your identity or other information about You to any third party who claims that material posted by You violates their rights, including their intellectual property rights or their right to privacy.
Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Property Portal or Website.
Terminate or suspend Your access to all or part of the Developer Website for any or no reason, including without limitation, any violation of these Terms of Use.
Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone submitting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
</Typography>  <Typography variant="body1" gutterBottom>
However, we do not undertake to review material after it is submitted to the Property Portal and/or posted to the Website, and cannot ensure prompt removal of objectionable material after it has been submitted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.
</Typography>  <Typography variant="body1" gutterBottom>
11. Content Standards
These content standards apply to any and all Developer Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, Developer Contributions must not:
</Typography>  <Typography variant="body1" gutterBottom>
Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.
Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.
Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.
Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy
Be likely to deceive any person.
Promote any illegal activity, or advocate, promote, or assist any unlawful act.
Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.
Impersonate any person, or misrepresent Your identity or affiliation with any person or organization.
Involve commercial activities or sales other than the presentation of information concerning real estate, such as contests, sweepstakes, and other sales promotions, barter, or advertising.
Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.
</Typography>  <Typography variant="body1" gutterBottom>
12. Image Licensing
Scope of this Section: This section applies to any image, graphics, digital assets, or videos (collectively the “Image Assets”) owned by, or created on behalf of, the Developer and delivered to or obtained by LX Collection associated with the Developer’s marketing efforts to market its real property being developed and/or sold to the general public through the Website. Developer represents to LX Collection that Developer has the necessary rights and the authority to grant the license of this Section of the Image Assets to the LX Collection.
</Typography>  <Typography variant="body1" gutterBottom>
Rights: All Image Assets and rights relating to them remain the sole and exclusive property of the Developer. Developer grants LX Collection and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns a non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide license to the Image Assets, including without limitation the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose. You further understand that Website users may link pages from the Website or related social media to other web sites or social media.
</Typography>  <Typography variant="body1" gutterBottom>
Copyright: Image Assets may contain copyright management information (CMI) at the discretion of the Developer in the form of either 1) a copyright notice © and/or 2) other copyright and ownership information embedded and/or 3) a photo credit in the metadata or elsewhere, unless otherwise agreed to by the Developer and LX Collection. Licensee will include Image Asset copyrights and ownership credits where deemed necessary when utilizing the Image Assets for marketing purposes.
</Typography>  <Typography variant="body1" gutterBottom>
Creation of New Image Assets: LX Collection will own the exclusive rights to any Image Assets created by Our team (“New Image Assets”) on behalf of, or at the request of, the Developer for promotional purposes on Our Channels. Use of New Image Assets by Developer must be approved in writing by Us.
</Typography>  <Typography variant="body1" gutterBottom>
Indemnification: Developer will indemnify and defend LX Collection against all claims, liability, damages, costs, and expenses, including reasonable legal fees and expenses, arising out of the creation or any use of the Image Assets or materials furnished by Developer. It is the Developer's responsibility to obtain the necessary licenses, copyright, and other rights to the Image Assets prior to licensing said Image Assets to Licensee.
</Typography>  <Typography variant="body1" gutterBottom>
13. Copyright Infringement
We comply with the Digital Millennium Copyright Act. If You believe that any matter on the Website violates Your copyright, please email info@lxcollection.com to send a notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat infringers.
</Typography>  <Typography variant="body1" gutterBottom>
14. Reliance on Information Posted
The information presented on or through the Property Portal or Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance You place on such information is strictly at Your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by You or any visitor to the Website, or by anyone who may be informed of any of its contents.
</Typography>  <Typography variant="body1" gutterBottom>
This Website includes content provided by third parties, including materials provided by third-party real estate owners or brokers, third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to You or any third party, for the content or accuracy of any materials provided by any third parties.
</Typography>  <Typography variant="body1" gutterBottom>
15. Information About You and Your Visits to the Website
All information we collect on this Website is subject to our Privacy Policy By using the Website, You consent to all actions taken by us with respect to Your information in compliance with the Privacy Policy. In furtherance of the commercial purposes of Your use of the Property Portal, You consent to the use of cookies and other tracking of Your use of the Property Portal.
</Typography>  <Typography variant="body1" gutterBottom>
16. Use of Information We Provide to You
The Website contains features to allow Website users to provide contact information to us that they have agreed we may provide to You, solely for purposes of Your communicating with such users concerning information related to specific property (“Property”) You have submitted to us via the Property Portal or in other ways. You agree that You will maintain and utilize such user information in accordance with Your privacy policy, and that, at a minimum and notwithstanding any provisions of Your privacy policy, You will comply with all applicable laws and regulations of relevant jurisdictions concerning privacy of personal information and the making of telephone calls or transmission of emails or other communications with consumers and will provide such information to third parties only to facilitate Your communication with such users. You agree that Your initial communication with any such user will only relate to Property in which the user requested information on, and You will not place (or allow to be placed) such user’s personal information on any mailing, emailing or telemarketing list without such user’s express consent.
</Typography>  <Typography variant="body1" gutterBottom>
17. Linking to the Website and Social Media Features
You may link to our homepage, provided You do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express written consent.
</Typography>  <Typography variant="body1" gutterBottom>
You must not, without our written consent:
</Typography>  <Typography variant="body1" gutterBottom>
Establish a link from any website that is not owned by You.
Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site, for example, framing, deep linking, or in-line linking.
Link to any part of the Website other than the homepage.
Otherwise take any action with respect to the materials on this Website that is inconsistent with any other provision of these Terms of Use.
The website from which You are linking, or on which You make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use.
</Typography>  <Typography variant="body1" gutterBottom>
You agree to cooperate with us in causing any unauthorized framing or linking immediately to stop. We reserve the right to withdraw linking permission without notice.
</Typography>  <Typography variant="body1" gutterBottom>
We may disable all or any social media features and any links at any time without notice in our discretion.
</Typography>  <Typography variant="body1" gutterBottom>
18. Geographic Restrictions
The owner of the Website is based in the State of New York in the United States. We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries.
</Typography>  <Typography variant="body1" gutterBottom>
19. Disclaimer of Warranties
You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Property Portal will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data.
</Typography>  <Typography variant="body1" gutterBottom>
TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE PROPERTY PORTAL, WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PROPERTY PORTAL OR WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON THEM, OR ON ANY WEBSITE LINKED TO THEM. YOUR USE OF THE PROPERTY PORTAL, WEBSITE, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THEM IS AT YOUR OWN RISK.
</Typography>  <Typography variant="body1" gutterBottom>
THE PROPERTY PORTAL AND THE WEBSITE, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THEM ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE PROPERTY PORTAL OR WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE DEVELOPER PORTAL, THE WEBSITE, THEIR CONTENT INCLUDING WITHOUT LIMITATION REAL PROPERTY INFORMATION, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PROPERTY PORTAL OR WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE PROPERTY PORTAL, THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THEM WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
</Typography>  <Typography variant="body1" gutterBottom>
20. Limitation on Liability
TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE PROPERTY PORTAL OR WEBSITE, ANY WEBSITES LINKED TO THEM, ANY CONTENT ON THE PROPERTY PORTAL OR WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
</Typography>  <Typography variant="body1" gutterBottom>
The limitation of liability set out above does not apply to liability resulting from our gross negligence or willful misconduct.
</Typography>  <Typography variant="body1" gutterBottom>
THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
</Typography>  <Typography variant="body1" gutterBottom>
21. Indemnification
You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) (i) of intellectual property infringement concerning Developer Contributions or (ii) arising out of or relating to, Your violation of these Terms of Use or Your use of the Property Portal or Website, including, but not limited to, Your Developer Contributions, any use of the Property Portal or Website's content, services, and products other than as expressly authorized in these Terms of Use, or Your use of any information obtained from the Property Portal or Website.
</Typography>  <Typography variant="body1" gutterBottom>
22. Governing Law
Unless otherwise provided in a marketing agreement between You and All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including noncontractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of New York without giving effect to any choice or conflict of law provision or rule (whether of the State of New York or any other jurisdiction).
</Typography>  <Typography variant="body1" gutterBottom>
23. Jurisdiction
Unless otherwise provided in a marketing agreement between You and us, any legal suit, action, or proceeding arising out of, or related to, these Terms of Use, the Property Portal or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of [New York][, in each case located in the City of [New York] and County of [New York]. You waive any and all objections to the exercise of jurisdiction over You by such courts and to venue in such courts.
</Typography>  <Typography variant="body1" gutterBottom>
24. Arbitration
Unless otherwise agreed in a marketing agreement between You and Us:
</Typography>  <Typography variant="body1" gutterBottom>
YOU AND THE COMPANY AGREE TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A JURY OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS ACCESS TO DISCOVERY, ALSO MAY BE UNAVAILABLE OR LIMITED IN ARBITRATION.
</Typography>  <Typography variant="body1" gutterBottom>
Any dispute between You and the Company and its agents, employees, officers, directors, principals, successors, assigns, subsidiaries or affiliates arising from or relating to these Terms and their interpretation or the breach, termination or validity thereof, the relationships which result from these Terms, including disputes about the validity, scope or enforceability of this arbitration provision (collectively, “Covered Disputes”) will be settled by binding arbitration administered by the American Arbitration Association (AAA):
</Typography>  <Typography variant="body1" gutterBottom>
The arbitration shall be conducted under the AAA’s Commercial Arbitration Rules in effect on the date of initiation of arbitration. The arbitration shall be seated in New York County, New York before a single arbitrator, or before three arbitrators if the amount of the claim is more than one million dollars. Payment of all filing, administration and arbitrator fees will be governed by the AAA’s rules.
</Typography>  <Typography variant="body1" gutterBottom>
The arbitrator will have the power to grant whatever relief would be available in court under law or in equity and any award of the arbitrator(s) will be final and binding on each of the parties and may be entered as a judgment in any court of competent jurisdiction. The arbitrator will not, however, have the power to award punitive or exemplary damages, the right to which each party hereby waives, and the arbitrator will apply applicable law and the provisions of these Terms of Use and the failure to do so will be deemed an excess of arbitral authority and grounds for judicial review. The Company and You agree that any Covered Dispute will be submitted to arbitration on an individual basis only. Neither the Company nor You are entitled to arbitrate any Covered Dispute as a class, representative or private attorney general action and the arbitrator(s) will have no authority to proceed on a class, representative or private attorney general basis. If any provision of the agreement to arbitrate in this section is found unenforceable, the unenforceable provision will be severed and the remaining arbitration terms will be enforced (but in no case will there be a class, representative or private attorney general arbitration). Regardless of any statute or law to the contrary, notice of any claim arising from or related to these Terms must be made within one (1) year after such claim arose or be forever barred. For purposes of this section, these Terms and related transactions will be subject to and governed by the Federal Arbitration Act, title 9 of the United States Code.
</Typography>  <Typography variant="body1" gutterBottom>
25. Limitation on Time to File Claims
ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
</Typography>  <Typography variant="body1" gutterBottom>
26. Waiver and Severability
No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.
</Typography>  <Typography variant="body1" gutterBottom>
If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
</Typography>  <Typography variant="body1" gutterBottom>
27. Entire Agreement
The Terms of Use and our Privacy Policy constitute the sole and entire agreement between You and LX Collection LLC regarding the Property Portal and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Property Portal.
</Typography>  <Typography variant="body1" gutterBottom>
All notices of copyright infringement claims and all other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to: info@lxcollection.com.
</Typography>
            </div>
<br /><br />
            <Checkbox 
                id="terms" 
                fullWidth
                label="Terms and conditions" 
                variant="outlined" 
            />
Developer agrees to the Terms of Service and Image Licensing Agreement


            </Paper>
            
        </Grid> 
      </Grid>
    </div>
  );
}


 