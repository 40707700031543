import React, { Component, useState } from 'react';
import { Router } from 'react-router-dom';
import { IntlProvider } from "react-intl";

import {language, messages} from "./locale/index";
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import light from './theme';
import dark from './dark-theme';
import './assets/scss/index.scss';
import Routes from './Routes';

const browserHistory = createBrowserHistory();
 


export default function App() {
  const [theme, setTheme] = useState(light);
  const updateTheme = () => {
    setTheme(dark)
  }
  
    return (
      <IntlProvider locale={language} messages={messages}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes  updateTheme={updateTheme} />
          </Router>
        </ThemeProvider>
      </IntlProvider>
    );
   
}
