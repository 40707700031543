import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h2" gutterBottom>
                Welcome to LX Collection, Pamela Talley.
            </Typography>
            <Typography variant="body1" gutterBottom>
            As part of LX Collection, you are joining an elite class of properties on the first global marketing platform dedicated to luxury condominiums.
            </Typography>
            <Typography variant="body1" gutterBottom>
            The LX Property Portal enables simple entry and ongoing updates of your property’s details, imagery, and availability on LX Collection. Once you are ready to begin, please select the property you are uploading for the first time or the property you would like to edit.
            </Typography>
            <Typography variant="body1" gutterBottom>
            In each stage, we have provided notes for you to consider. Please click on the ℹ icon for further details within each section. If you have any questions, please feel free to email our team at propertyportal@LXCollection.com.
            </Typography>
            <Typography variant="body1" gutterBottom>
            We are thrilled to have you as part of LX Collection. Thank you.
            </Typography>
            <Typography variant="body1" gutterBottom>
            Select the property you would like to upload or edit:
            </Typography>        
            </Paper>
            
        </Grid> 
      </Grid>
    </div>
  );
}


 